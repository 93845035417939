<template>
	<div class="business">
		<!-- 介绍 -->
		<div class="business-introduced">
			<!-- 
            <div><img :src="storedetail.shop_bgimg" alt /> </div>
            -->
			<div class="business-introduced-top">
				<div class="left">
					<p>{{storedetail.shop_name}}</p>
					<div>
						<img src="../../assets/cp05.png" alt style="width:28px;height:auto" />
						<span @click="openLocation">距您{{distance | handleDistance}}</span>
						<span class="p1" @click="openLocation">{{storedetail.shop_address}}</span>
					</div>
				</div>
				<div @click="call(storedetail.shop_phone)" class="right">
					<img src="../../assets/dianhua.png" alt style="width:28px;height:auto" />
					<div>
						<p>商家</p>
						<p>电话</p>
					</div>
				</div>
			</div>
			<div class="address">营业时间：{{storedetail.business_hours}}</div>
		</div>

		<!-- 商品内容 -->
		<div class="allcontent">
			<div class="allcontent-1"></div>
			<!-- 商品列表 -->
			<goods-list :recommends="storegoods" :finished="finished" :loading="loading" :isShow="isShow"
				@getMore="getMore"></goods-list>
		</div>
		<!-- 回到顶部 -->
		<div class="callback" v-if="btnFlag" @click="backTop">
			<img src="../../assets/top.png" alt />
		</div>
	</div>
</template>


<script>
	import {
		StoreDetail,
		StoreGoods
	} from "@/http/api";
	import {
		changeDis
	} from "@/common/data";
	import GoodsList from "@/components/goods/GoodsList";
	import {
		openLocation
	} from "@/common/wxJssdk";

	export default {
		components: {
			GoodsList
		},
		data() {
			return {
				btnFlag: false,
				shop_id: "",
				distance: this.$route.query.distance,
				isShow: false,
				isLoad: true,
				pages: 1,
				loading: false,
				finished: false,
				storedetail: [],
				storegoods: []
			};
		},

		mounted() {
			let route = this.$route.query;
			if (route.shop_id) {
				this.shop_id = route.shop_id;
				this.distance = route.distance;

				window.addEventListener("scroll", this.scrollToTop);
				this.StoreDetail();
				this.StoreGoods();
			} else {
				this.$toast("获取店铺参数错误");
			}
		},
		destroyed() {
			window.removeEventListener("scroll", this.scrollToTop);
		},
		methods: {
			//  跳转地图
			openLocation() {
				let shopInfo = this.storedetail;
				openLocation({
					latitude: parseFloat(shopInfo.location_lat),
					longitude: parseFloat(shopInfo.location_lng),
					name: shopInfo.shop_name,
					address: shopInfo.shop_address
				});
			},

			// 点击图片回到顶部方法，加计时器是为了过渡顺滑
			backTop() {
				const that = this;
				let timer = setInterval(() => {
					let ispeed = Math.floor(-that.scrollTop / 5);
					document.documentElement.scrollTop = document.body.scrollTop =
						that.scrollTop + ispeed;
					if (that.scrollTop === 0) {
						clearInterval(timer);
					}
				}, 16);
			},

			// 为了计算距离顶部的高度，当高度大于60显示回顶部图标，小于60则隐藏
			scrollToTop() {
				const that = this;
				let scrollTop =
					window.pageYOffset ||
					document.documentElement.scrollTop ||
					document.body.scrollTop;
				that.scrollTop = scrollTop;
				if (that.scrollTop > 60) {
					that.btnFlag = true;
				} else {
					that.btnFlag = false;
				}
			},

			//  店铺详情
			async StoreDetail() {
				const res = await StoreDetail({
					data: {
						shop_id: this.shop_id
					},
				});
				this.storedetail = res.data;
			},

			//  店铺商品
			async StoreGoods() {
				if (this.isLoad) {
					const res = await StoreGoods({
						data: {
							shop_id: this.shop_id,
							pages: this.pages
						}
					});
					this.isShow = true;
					this.loading = false;
					if (res.numPage == res.data.length) {
						this.pages++;
						this.finished = false;
					} else {
						this.isLoad = false;
						this.finished = true;
					}
					this.storegoods = this.storegoods.concat(res.data);
				}
			},
			//点击加载更多
			getMore() {
				this.StoreGoods();
			},
			//拨打电话
			call(tel) {
				window.location.href = "tel:" + tel;
			}
		},
		filters: {
			handleDistance(dis) {
				return changeDis(dis);
			}
		},
		created() {}
	};
</script>


<style lang="scss" scoped>
	.business {
		width: 100%;
		height: 100%;
		// padding-bottom: 10rem;
		position: relative;

		.details-top {
			width: 100%;
			display: flex;
			justify-content: space-between;
			padding: 0.3rem;
			box-sizing: border-box;
			font-size: 0.38rem;
			background: #f4f4f4;
		}

		.business-introduced {
			width: 100%;
			// height: 115px;

			div:nth-child(1) {
				img {
					width: 100%;
					height: 2.2rem;
					display: block;
				}
			}

			.business-introduced-top {
				background: #fff;
				border-radius: 0.08rem;
				border: 1px solid #ecedef;
				display: flex;
				justify-content: space-between;
				align-items: center;
				font-size: 0.22rem;

				.left {
					width: 80%;
					height: 100%;
					padding: 12px 12px;
					box-sizing: border-box;

					p {
						font-size: 0.36rem;
						color: #000;
						margin-bottom: 6px;
					}

					div {
						display: flex;
						align-items: center;

						img {
							width: 0.36rem !important;
							height: auto;
							margin-right: 6px;
							font-size: 13px;
						}
					}
				}

				.right {
					flex: 1;
					height: 100%;
					display: flex;
				}
			}
		}

		.address {
			font-size: 13px;
			padding: 0 12px;
			box-sizing: border-box;
			margin-top: 9px;
		}

		.allcontent {
			width: 100%;
			height: max-content;
			background: #f4f4f4 url(../../assets/sh01.png) no-repeat top;
			background-size: 100%;
			border-radius: 0.05rem;
			border-top: #f4f4f4 solid 0.2rem;
			position: relative;

			.content {
				background: none;
			}

			.allcontent-1 {
				display: flex;
				padding-top: 1rem;
				// padding-left: 2.52rem;
				box-sizing: border-box;

				p:nth-child(1) {
					width: 0.4rem;
					height: 0.03rem;
					background: #fff;
					margin-top: 0.15rem;
					margin-right: 0.12rem;
				}

				p:nth-child(2) {
					font-size: 0.3rem;
					color: #fff;
				}

				p:nth-child(3) {
					width: 0.4rem;
					height: 0.03rem;
					background: #fff;
					margin-top: 0.15rem;
					margin-left: 0.12rem;
				}
			}
		}

		.callback {
			position: fixed;
			right: 0.2rem;
			bottom: 2rem;

			img {
				width: 1.16rem;
				height: 1.11rem;
				opacity: 3;
			}
		}
	}
</style>
